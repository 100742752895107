<template>
  <v-dialog :value="value" persistent width="1000">
    <v-card>
      <v-toolbar flat dark color="#00aff0">
        <v-toolbar-title>
          {{attr.name}} Activity
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="pa-0 ma-0">
        <v-data-table :items="attrvalues" :headers="headers" height="300" no-data-text="No Activity to Display" hide-default-footer dense>

        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip v-if="attrvalue !== null" top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" right :color="statusColor(item)">{{statusIcon(item)}}</v-icon>
            </template>
            <span>{{statusTooltip(item)}}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.value`]="{ item }">
          <span>{{displayValue(item)}}</span>
        </template>

        <template v-slot:[`item.start_dt`]="{ item }">
          <span>{{moment(item.start_dt).format($config.date_display)}}</span>
        </template>

        <template v-slot:[`item.end_dt`]="{ item }">
          <span v-if="item.end_dt !== null">{{moment(item.end_dt).format($config.date_display)}}</span>
          <span v-else>---</span>
        </template>

        <template v-slot:[`item.updated_on`]="{ item }">
          <span>{{moment(item.updated_on).format($config.date_display)}}</span>
        </template>

        <template v-slot:[`item.updated_by`]="{ item }">
          <span>{{item.updated_by}}</span>
        </template>

        </v-data-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'AttrActivity',

  props: [
    'value',
    'attr',
    'attrvalue',
    'attrvalues'
  ],

  computed: {
    timeSenstive () {
      return this.attr.is_time_sensitive
    },

    keyValue () {
      if (this.attr.data_type.constant === 'KEY_VALUE') {
        return true
      } else {
        return false
      }
    },

    date () {
      if (this.attr.data_type.constant === 'DATE') {
        return true
      } else {
        return false
      }
    },

    headers () {
      if (this.timeSenstive && !this.keyValue) {
        return [
          {},
          { text: 'Status', value: 'status', class: 'black--text', sortable: true },
          { text: 'Value', value: 'value', class: 'black--text', sortable: true },
          { text: 'Start Date', value: 'start_dt', class: 'black--text', sortable: true },
          { text: 'End Date', value: 'end_dt', class: 'black--text', sortable: true },
          { text: 'Updated On', value: 'updated_on', class: 'black--text', sortable: true },
          { text: 'Updated By', value: 'updated_by', class: 'black--text', sortable: true }
        ]
      } else if (this.timeSenstive && this.keyValue) {
        return [
          {},
          { text: 'Status', value: 'status', class: 'black--text', sortable: true },
          { text: 'Value', value: 'attr_key_value.name', class: 'black--text', sortable: true },
          { text: 'Start Date', value: 'start_dt', class: 'black--text', sortable: true },
          { text: 'End Date', value: 'end_dt', class: 'black--text', sortable: true },
          { text: 'Updated On', value: 'updated_on', class: 'black--text', sortable: true },
          { text: 'Updated By', value: 'updated_by', class: 'black--text', sortable: true }
        ]
      } else if (!this.timeSenstive && !this.keyValue) {
        return [
          {},
          { text: 'Value', value: 'value', class: 'black--text', sortable: true },
          { text: 'Updated On', value: 'updated_on', class: 'black--text', sortable: true },
          { text: 'Updated By', value: 'updated_by', class: 'black--text', sortable: true }
        ]
      } else {
        return [
          {},
          { text: 'Value', value: 'attr_key_value.name', class: 'black--text', sortable: true },
          { text: 'Updated On', value: 'updated_on', class: 'black--text', sortable: true },
          { text: 'Updated By', value: 'updated_by', class: 'black--text', sortable: true }
        ]
      }
    }
  },

  methods: {
    displayValue (item) {
      if (this.keyValue) {
        return item.attr_key_value.name
      } else if (this.date) {
        return this.moment(item.value).format(this.$config.date_display)
      } else {
        return item.value
      }
    },

    statusIcon (item) {
      if (this.attrvalue.id === item.id) {
        return 'mdi-checkbox-marked-circle-outline'
      } else if (item.start_dt > this.attrvalue.end_dt) {
        return 'mdi-calendar-clock'
      } else {
        return 'mdi-close-circle-outline'
      }
    },

    statusColor (item) {
      if (this.attrvalue.id === item.id) {
        return 'green'
      } else if (item.start_dt > this.attrvalue.end_dt) {
        return 'orange'
      } else {
        return 'red'
      }
    },

    statusTooltip (item) {
      if (this.attrvalue.id === item.id) {
        return 'Active'
      } else if (item.start_dt > this.attrvalue.end_dt) {
        return 'Upcoming'
      } else {
        return 'Expired'
      }
    }
  }
}
</script>
