var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "1000" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dark: "", color: "#00aff0" } },
            [
              _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.attr.name) + " Activity ")
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "pa-0 ma-0" },
            [
              _c("v-data-table", {
                attrs: {
                  items: _vm.attrvalues,
                  headers: _vm.headers,
                  height: "300",
                  "no-data-text": "No Activity to Display",
                  "hide-default-footer": "",
                  dense: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.status",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm.attrvalue !== null
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    right: "",
                                                    color: _vm.statusColor(item)
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.statusIcon(item))
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.statusTooltip(item)))
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.value",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [_vm._v(_vm._s(_vm.displayValue(item)))])
                        ]
                      }
                    },
                    {
                      key: "item.start_dt",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .moment(item.start_dt)
                                  .format(_vm.$config.date_display)
                              )
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.end_dt",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.end_dt !== null
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(item.end_dt)
                                      .format(_vm.$config.date_display)
                                  )
                                )
                              ])
                            : _c("span", [_vm._v("---")])
                        ]
                      }
                    },
                    {
                      key: "item.updated_on",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .moment(item.updated_on)
                                  .format(_vm.$config.date_display)
                              )
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.updated_by",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span", [_vm._v(_vm._s(item.updated_by))])]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }